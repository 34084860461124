import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useTanyaContext } from "stores/tanya/tanya-context";
import HomeBanner from "public/assets/home/homebanner.png";
import BannerImage from "public/assets/bimbel-online/bimbel-banner.svg";
import BannerImageDesktop from "public/assets/bimbel-online/bimbel-banner-desktop.png";
import React, { useState } from "react";
import trackEvent from "services/mixpanel/mixpanel";
import { bannerIntentEventNames } from "services/mixpanel/tanya-events";
import { getLeadSourceFromPath } from "utils/utils";
import { PriceInfoCard } from "../price-info-card/price-info-card";
import {
  LeadGenerationImgWrapper,
  LeadGenerationSectionContainer,
  LeadGenerationSectionWrapper,
  LeadGenerationTextContainer,
  LeadGenerationTextWrapper,
  LeadGenerationTag,
  LeadGenerationTagContainer,
  Tag1,
  Tag2,
  MainContent,
  SpacerForAbsolute,
  LeadGenerationPriceButtonWrapper,
  LeadGenerationSubheading,
  LeadGenerationHeading
} from "./hero-lead-generation-section.styles";
import { useMediaQuery } from "hooks/use-media-query/use-media-query";
import { VideoModal } from "components/video-modal/video-modal";

interface IHeroLeadGenerationSectionProps {
  onPriceInfoCTAClick: () => void;
  buttonCTAText?: string;
  source: "Banner" | "Home";
}

export const HeroLeadGenerationSection: React.FC<
  IHeroLeadGenerationSectionProps
> = ({ buttonCTAText, source }) => {
  const isMobile = useMediaQuery("mobile");
  const router = useRouter();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const leadSource = getLeadSourceFromPath(router.pathname);
  const { contactMeForm } = useTanyaContext();
  const { utm_campaign, utm_source, utm_medium, utm_term, utm_content } =
    router.query;

  const handleCTAClick = () => {
    trackEvent({
      eventName: bannerIntentEventNames.primaryCTAClicked,
      payload: { leadSource }
    });

    contactMeForm.setContactMeFormData({
      show: true,
      metaData: { utm_term, utm_source, utm_medium, utm_campaign, utm_content }
    });
  };

  const handleImageClick = () => {
    if (source === "Banner") {
      setIsVideoPlaying(true);
    }
  };

  return (
    <LeadGenerationSectionWrapper>
      <LeadGenerationSectionContainer>
        <LeadGenerationImgWrapper
          onClick={handleImageClick}
          style={{ cursor: source === "Banner" ? "pointer" : "default" }}
        >
          <Image
            src={
              source === "Banner"
                ? isMobile
                  ? BannerImage
                  : BannerImageDesktop
                : HomeBanner
            }
            alt={
              source === "Banner"
                ? "Banner description here"
                : "Bimbel online mulai 95.000 / bulan"
            }
            layout="responsive"
            sizes="(min-width: 768px) 33vw, 80vw"
            priority
          />
        </LeadGenerationImgWrapper>
        <SpacerForAbsolute
          page={source === "Banner" ? "bimbelPage" : "homePage"}
        />
        <MainContent page={source === "Banner" ? "bimbelPage" : "homePage"}>
          <LeadGenerationTextWrapper>
            <LeadGenerationTextContainer>
              <LeadGenerationSubheading>
                {source === "Banner" ? (
                  <>
                    Mewujudkan murid Indonesia yang <br /> percaya diri dan
                  </>
                ) : (
                  <>Ingin Anak</>
                )}
              </LeadGenerationSubheading>
              <LeadGenerationHeading>
                Paham Matematika{source === "Banner" ? "" : "?"}
              </LeadGenerationHeading>
              {source === "Banner" ? (
                <LeadGenerationTagContainer>
                  <Tag1>
                    <LeadGenerationTag>Bimbel Online</LeadGenerationTag>
                    <LeadGenerationTag>Interaksi 2-arah</LeadGenerationTag>
                  </Tag1>
                  <Tag2>
                    <LeadGenerationTag>Guru dan teman seru</LeadGenerationTag>
                  </Tag2>
                </LeadGenerationTagContainer>
              ) : (
                <LeadGenerationTagContainer>
                  <Tag1>
                    <LeadGenerationTag>Bimbel Online</LeadGenerationTag>
                    <LeadGenerationTag>4 SD - 12 SMA</LeadGenerationTag>
                  </Tag1>
                  <Tag2>
                    <LeadGenerationTag>95.000/bulan</LeadGenerationTag>
                  </Tag2>
                </LeadGenerationTagContainer>
              )}
              <LeadGenerationPriceButtonWrapper>
                <PriceInfoCard
                  onCTAClick={handleCTAClick}
                  ctaTitle={
                    buttonCTAText ? buttonCTAText : "Minta info bimbel "
                  }
                />
              </LeadGenerationPriceButtonWrapper>
            </LeadGenerationTextContainer>
          </LeadGenerationTextWrapper>
        </MainContent>
        {isVideoPlaying && (
          <VideoModal
            onClose={() => setIsVideoPlaying(false)}
            videoUrl="https://www.youtube.com/watch?v=Z64Z-iUgu0s"
          />
        )}
      </LeadGenerationSectionContainer>
    </LeadGenerationSectionWrapper>
  );
};
